import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MuiSecondaryButton = (props) => {
    const { children, classes: { root } } = props;
    return (
        <Button
            classes={{ root }}
            variant="outlined"
            color="primary"
            {...props}
        >
            {children}
        </Button>
    );
};

const styles = () => ({
    root: {
        height: 45,
        marginTop: 10,
        marginBottom: 10,
    },
});

MuiSecondaryButton.defaultProps = {
    children: null,
};

MuiSecondaryButton.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
};

export default withStyles(styles)(MuiSecondaryButton);
